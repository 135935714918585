
 .drawerSearch{
    background-color: rgb(17, 24, 39)!important;
} 

.childrenBuscadorTabs{
    width: 100%!important;
    height: 100%!important;
    border-radius: 9999px!important;
    opacity: 0.2 !important;
}

.menosMxTabsBuscador{
    min-height: 40px!important;
    margin-left: -4px!important;
    margin-right: -4px!important;
    padding-left: 24px!important;
    padding-right: 24px!important;
    width: 100%!important;
}

.clasesindicadorTabsBuscador{
    display: flex !important;
    justify-content: center!important;
    background-color: transparent!important;
   /*  width: 100%!important; */
    height: 100%!important;
}

@media screen and  (min-width: 1280px) {
    .TabsdentrodeBusqueda{
        padding-right: 4vw!important;
    }
    
}

@media screen and  (min-width: 960px) {
    .textoBuscaCand{
        font-size: 36px!important;
        line-height: 1.375!important;
    }
    
}

@media screen and  (min-width: 600px) {
    .TabsdentrodeBusqueda{
        padding-top: 24px!important;
    }

    .paddingBuscadorTab32{
        padding-left: 32px!important;
        padding-right: 4vw!important;
    }

    .flexoHeaderBusca{
        flex-direction: row!important;
        align-items: center!important;
        margin-top: 48px!important;
        margin-bottom: 48px!important;
    }

    .margeneoBotonesBuscadorDer{
        margin-top: 0!important;
        margin-left: 8px!important;
        margin-right: 8px!important;
    }

    .textoTabBuscadorDet{
        font-size: 16px!important;
    }
}

.botonRaroSpaceX{
    --tw-space-x-reverse: 0!important;
    margin-left: calc(1.2rem*(1 - var(--tw-space-x-reverse)))!important;
    margin-right: calc(1.2rem*var(--tw-space-x-reverse))!important;
}

.textoTabBuscadorDet:hover{
 /* border-bottom: 0px!important; */
 background-color:rgb(241, 245, 249)!important;
}

.breakEnBuscador{
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
}

@media screen and  (max-width: 960px) {
    /* .breakEnBuscador{
        width: 50% !important;
    } */
/*     .solventeBuscador{
        display: block !important;
    } */

    .evenBuscadorBreak{
        display: block !important;
        width: 100% !important;
        min-width: 50%!important;
    }

    .evenBuscadorBreak2{
        display: block !important;
        width: 100% !important;
        min-width: 100%!important;
    }

    .breakEnBuscador{
        width: 100% !important;
        min-width: 50%!important;
    }
    .solventeBuscador2{
        display: block !important;
    }

    
}

@media screen and (max-width: 600px) {
    /* .breakEnBuscador{
        width: 50% !important;
    } */
/*     .solventeBuscador{
        display: block !important;
    } */

    .solventeBuscador{
        display: block !important;
    }

    .evenBuscadorBreak{
        width: 100% !important;
    }

    .breakEnBuscador{
        width: 100% !important;
    }
}

@media screen and (max-width: 600px) {
    /* .breakEnBuscador{
        width: 50% !important;
    } */
/*     .solventeBuscador{
        display: block !important;
    } */

   .separArrIdioms{
    padding-top: 8px!important;
   }

.eliminBusquedaIdio{
    top: 22px !important;
}
}

.paddingSeparExpTot{
    padding-left: 24px!important;
    padding-right: 24px!important;
}

.listaBuscador{
   color: rgba(255, 255, 255, 0.7)!important;
}

.listaBuscador2{
    color: #026670!important;
 }

 .listaCompletaBuscador2{
    background-color: rgb(255, 255, 255, 0.05)!important;
}
.listaCompletaBuscador2 .listaBuscador{
    color: white !important;
}
.listaCompletaBuscador2 .listaBuscadorIcono{
    color: white !important;
}


.listaCompletaBuscador:hover{
    background-color: rgb(255, 255, 255, 0.05)!important;
}

.listaCompletaBuscador:hover .listaBuscador{
    color: white !important;
}

.listaCompletaBuscador:hover .listaBuscadorIcono{
    color: white !important;
}

.popovereoBusqueda{
    border-radius: 8px!important;
    background-color: #026670!important;
}

.backBusq{
   background-color: rgb(241, 245, 249) !important
}

.otroLinkHeight{
    /* position: absolute !important; */
    height: 3.0rem !important;
   /*  margin-top: 20px!important; */
  
    border-radius: 9999px!important;
    border-top-left-radius: 9999px!important;
    border-bottom-left-radius: 9999px!important;
    
    /* padding-left: 16px!important; */
    padding-right: 16px!important;
   /*  border-top:1px solid rgb(133, 133, 133)!important;
    border-bottom: 1px solid rgb(133, 133, 133)!important;
    border-right: 1px solid rgb(133, 133, 133)!important;
    border-left: 1px solid rgb(133, 133, 133)!important; */
}





.busquedaRightAt2{
   left: calc(1.2rem + 6.4rem + 1.6rem + 16px ) !important;
   top: calc(6rem - 23px) !important;
   padding-left:4px!important;
   padding-right:5px!important;
   padding-top: 1px!important;
   padding-bottom: 1px!important;
   border-radius: 22px!important;
   color:rgba(0, 0, 0, 0.87)!important;
   line-height: 0.5!important;
   opacity: 0.7 !important;
}



.busquedaRightAt2:hover{
    background-color: #fff!important;
    cursor:default!important;
  }

@media screen and (max-width: 600px) {
.cosadopodopo{
padding-left: 0px!important;
padding-right: 8px!important;
}



.dopodopoPagper{
    padding-left: 1.2rem!important;
    padding-right: 1.2rem!important;
}
.cambioMargeneoLeft{
    margin-left: 0px!important;
}
.objetivoBusquedaEmpresa{
    left: calc(0rem + 6.4rem + 0rem + 14px) !important;
}
.objetivoBusquedaBeneficios{
    left: calc(0rem + 6.4rem + 0rem + 14px + 162.04px + 4px) !important;
}

.objetivoBusquedaBeneficios2{
    left: calc(0rem + 6.4rem + 0rem + 14px + 0px + 0px) !important;
}
}

.hoverArribaFlechaBusq:hover .hoverFlechaBusq{
    /* color: #FF9800 !important; */
   /*  color: dimgray !important; */
   color: gray !important;
}



/* .contentoDialogBusq{
    display: contents!important;
} */

.contentoDialogBusq > .MuiPaper-root{ 
    display: contents!important;
 }




@media screen and (max-width: 990px) {
 .hoverArribaFlechaBusq{
    top: 3.5rem !important;
}
}



.hoverBotondisab{
    background-color: #aaa!important;
    color: rgba(0, 0, 0, 0.87)!important;
}

.hoverBotondisab:hover{
    background-color: gray!important;
}


@media screen and (max-width: 600px) {
.escondeOver{
overflow:hidden!important;
}

.pruebequeTypo{
    font-size: 12px!important;
    line-height: 24px!important;
    margin-top: auto!important;
    margin-bottom: 0px!important;
}
}

@media screen and (max-width: 1020px) {
    
    
    .ordenarResultados{
       
         width: 38px!important;  
         color: transparent !important;
    } 
    }
    
.flechaBusquedaResultados:hover{
    color:#004e56!important;
   
}

.MuiIconButton-root.flechaBusquedaResultados{
    background-color: transparent!important;
}

@media screen and (max-width: 720px){
    .flechaBusquedaResultados{
        display: none!important;
    }
  .escondidoChico{
    display: none!important;
  }
}

.arregloHoverBotonBusq:hover{
    box-shadow: none!important;
} 

.ukara{
    height: 10px !important;
}

.dialogoElim{
    max-width: 500px!important;
    margin: auto !important;
}


@media screen and  (min-width: 600px){
   
  .searchHelpCenter3{
    max-width: 30rem!important;
    margin: auto!important;
    margin-top: 32px!important;

  }

}

 .searchHelpCenter3:hover > .MuiOutlinedInput-notchedOutline{
        border-color: #004e56!important;
        border-width:2px!important;
    }
    .searchHelpCenter3 > .MuiOutlinedInput-notchedOutline{
        border-color: gray!important;
       
    }

    .Mui-focused.searchHelpCenter3 > .MuiOutlinedInput-notchedOutline{
        border-color: #004e56!important;
        border-width:2px!important;
    }

    .sinmargeneoBusqueda{
        margin-right: 0!important;
        margin-left: 0!important;
        
    }