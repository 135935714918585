.formu{

    justify-content: space-between!important;
}
.mu{
    max-width: 700px!important;
    justify-content: center!important;
    
    width: 100%;
    min-height: 100%!important;
    border-radius: 0!important;
}


.contenedor-form{
    padding: 16px 32px!important;
}

.entremed{
    justify-content: space-around!important;
}

.sueldo-ancho{
    width:350px!important;
    max-width: 100%!important;
  /*  justify-content: center!important;
   align-items: center!important; */
}

.sueldo-ancho2{
    width:400px!important;
  /*  justify-content: center!important;
   align-items: center!important; */
}

.cursor{
    cursor: not-allowed!important;
}

@media (min-width:600px) {
    .sueldo-ancho2{
        width:400px!important;
    }
}

@media (max-width:600px) {
    .sueldo-ancho2{
        width:90%!important;
    }
}

.arriba-frecuencia{
    margin-top: 16px!important;
   
}

.alineacion-sueldo{
    display:flex!important;
    justify-content: center!important;
   align-items: center!important;
}

.mejor2{
    display: grid!important;
}

@media (min-width:600px) {
    .mejor2{
       display:block!important;
    }
}


@media (min-width: 800px) {
    .mu{
        border-radius: 8px!important;
        box-shadow: rgb(76 78 100 / 22%) 0px 2px 10px 0px!important;
    }

    .contenedor-form{
        padding: 32px 64px!important;
    }
}

.bloqueado{
    background-color: white!important;
    color: rgba(0, 0, 0, 0.26)!important;
    font-size: 0.75rem!important;
}

.hidden{
    display:none!important;
}

.bloqueado:hover ~ .hidden{
    display: inline!important;
}


.pprox{
    display: inline;
    vertical-align: top;
}

.divdech{
    width: 100px !important;
}


.ancho{
    width:120px!important;
}


.ancho2{
    width:240px!important;
   
}

.ancho3{
    width:150px!important;
}

.duh2{
    justify-content: center!important;
    align-items: center!important;
}


.pais{
    display:block!important;
}

.pais2{
    vertical-align:text-bottom!important;
}



.sin-arriba2{
    padding-top: 24px!important;
}

.sin-arriba3{
    padding-top: 0px!important;
}

.sin-arriba4{
    padding-top: 16px!important;
}
.sinpad{
    padding-top: 0!important;
}

.antes-de-tit{
    padding-top: 32px!important;
}
.dopo-de-otro{
    padding-top: 24px!important;
}
.pd32{
    padding-top: 12px!important;
}

.boton-resp{
    display: grid !important;

}

@media (min-width:600px) {
    .boton-resp{
        display: block!important;
    }
}

.es-este{
    display:block!important;
    width: 90%!important;
}

@media (min-width:600px) {
    .es-este{
        /* display: flex!important; */
        display:contents!important
    }
}

.es-este2{
    display:block!important;
    width: 100%!important;
}

@media (min-width:600px) {
    .es-este2{
        /* display: flex!important; */
        display:contents!important
    }
}

.no-es-este{
    display:block!important;
    width: 90%!important;
}

@media (min-width:600px) {
    .no-es-este{
        /* display: flex!important; */
        display:flex!important
    }
}

.no-es-este2{
    display:block!important;
    width: 100%!important;
}

@media (min-width:600px) {
    .no-es-este2{
        /* display: flex!important; */
        display:flex!important
    }
}

.es-este3{
    display:block!important;
  
}

@media (min-width:600px) {
    .es-este3{
        display: block!important; 
        
    }
} 

.chigua {
    padding-left: 0!important;
}


.id1{
    margin-right:0px!important;
    
}

.id12{
    margin-right:0px!important;
    
}

@media (min-width:600px) {
    .id1{
        /* display: flex!important; */
        margin-right:8px!important;
    }
    .id12{
        /* display: flex!important; */
        margin-right:16px!important;
    }
}

@media (min-width:600px) and (max-width:960px) {
   
    .id12{
        /* display: flex!important; */
         margin-right:0px!important; 
        
    }
}

.id2{
    margin-left:0px!important;
    
}

.id22{
    margin-left:0px!important;
    
}

@media (min-width:600px) {
    .id2{
        /* display: flex!important; */
        margin-left:8px!important;
    }
    .id22{
        /* display: flex!important; */
        margin-left:16px!important;
    }
}

.basurilla{
    padding-top:68px!important;
    
}

@media (min-width:600px) {
    .basurilla{
       
        padding-top:28px!important;
    }
}

/* .responsivo{
    flex-basis: 100%!important;
    max-width: 100%!important;
   
}

@media (min-width:600px) {


.responsivo{
    flex-basis: 50%!important;
    max-width: 50%!important;
    display: block!important;
    flex-grow: 0!important;
}

} */

.fade-enter{
    opacity:0
    /* opacity: 1; */
    
}

.fade-enter-active{
    opacity:1;
    transition: opacity 700ms ease 0s;
    transition-delay: 300ms;
    /* opacity: 1; */
    
}

.fade-exit{
    opacity:1;
}
.fade-exit-active{
    opacity: 0;
    
    transition: opacity 1000ms ease 0s;
}

/* .fade2 {
    opacity: 0!important;
    transition: opacity 300ms ease 0s;
} */

.sin-nada{
    padding:0!important;
    width:100%!important
}

.mu2{
   /*  max-width: 800px!important; */
   align-items: center;
   background-color: #edeae5;
  /*  display:flex;
   justify-content: center; */
   padding-top: 16px;
   
}

.horizontal-no-mas{
    flex-direction: row!important;
    align-items: center!important;
    
}

.mu3{
    max-width: 300px;
    margin: auto
}

.prem{
    display: flex;
    justify-content: center;
    background-color: #edeae5;
 
    padding-top: 16px;
    padding-bottom: 16px;
    min-height: calc(100vh - 60px  - 38.5px - 60.66px - 59.46px);
}

.next{
    background-color: aqua;
}

.autocom-disa{
    color:transparent!important
}


/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


input[type=number] {
  -moz-appearance: textfield;
} */
 
.diechi{
    padding-top: 16px!important;
}

.diechi2{
    padding-top: 24px!important;
}

.empresason{
    padding-top: 16px!important;
}

.benvindo{
    max-width: "100px"!important;
}

.supaa{
    padding-top: 40px!important;
}

body {
    /* background-color: #edeae5!important; */
    background-color: #fff!important;
}

.veamos2{
    padding-Left:0!important; 
}

@media (min-width:600px) {
    .veamos2{
       
        padding-Left:16px!important; 
    }
}
.veamos3{
    padding-bottom:16px!important; 
}

.veamos4{
    padding-Left:16px!important; 
}

.butt{
    background-color: #f9b934!important;
}

.butt:hover {
    background-color: #FF9800!important;
    
}



.textopue{
    font-size: 0.5rem!important;
}

.subeLabel{
    line-height: 1.5rem!important;
}

.boton-subircv{
    color: rgba(0, 0, 0, 0.87)!important;
    background: #f9b934!important;
    cursor: pointer;
      border: none!important;
      text-align: center!important;
      box-sizing: border-box!important;
      font-weight: 500!important;
      border-radius: 0!important;
  }
  
  .boton-subircv:hover{
    color: rgba(0, 0, 0, 0.87);
      background: #FF9800!important;
    
  }

  .pepe1{
    width:50%;
    justify-content: center;
    margin-left: 25%;
margin-right: 25%;
  }

  @media (max-width:600px){
    .pepe1{
        width:80%;
        justify-content: center;
        margin-left: 10%;
    margin-right: 10%;
      }
  }

  

  .pepe2{
    width:30%;
    justify-content: center;
    margin-left: 35%;
margin-right: 35%;
  }

  .pepe3{
    width:40%;
    justify-content: center;
    margin-left: 30%;
margin-right: 30%;
  }

@media (max-width: 600px){
    .pepe3{
        width:60%;
        justify-content: center;
        margin-left: 20%;
    margin-right: 20%;
      }
    
}
  

  .boton-blanco{
    cursor: pointer;
 
  box-sizing: border-box!important;
    color: #241c15!important;
  box-shadow: inset 0 0 0 0.0625rem #241c15!important;
    background: rgba(36,28,21,0)!important;
  }
  
  .boton-blanco:hover{
    background: rgba(36,28,21,.1)!important;
  }

  @media (max-width:550px) {
  .MuiSlider-markLabel[data-index="0"]{
    transform: translateX(-35%)!important;
  
  }
  }

  

  @media (max-width:550px) {
    .MuiSlider-markLabel[data-index="3"]{
      transform: translateX(-65%)!important;
   
    }
    }

    /* @media (max-width:550px) { */
        .MuiSlider-markLabel[data-index="8"]{
          transform: translateX(-75%)!important;
        
        }
       /*  } */

       .aguinaldo{
        margin-bottom: 24px!important;
       }

       .cancelo{
        background: #f9b934!important;
        cursor: initial !important;
        border-radius:20px!important;
        padding: 0 16px!important;
        height: 40px!important;
        font-size: 0.875rem!important;
        line-height: 1.75!important;
        box-shadow: 0 10px 40px 10px rgb(140 152 164 / 18% )
       }

       .cancelo:hover{
        background: #f9b934!important;
        cursor: initial !important;
        
       }

.peque{
    padding-bottom: 1px!important;
}

@media (max-width:550px) {
.conte1{
    display:block!important;
    
}
}

@media (max-width:600px) {
    .cntri{
        display:flex!important;
        justify-content: center!important;
        align-items: center!important;
        margin: auto!important;
       
    }
    }

    @media (max-width:600px) {
        .cntri2{
            display:flex!important;
            justify-content: center!important;
            align-items: center!important;
            margin: auto!important;
            padding-top: 16px!important;
        }
        }
        .peque{
            font-size: 0.75rem!important;
                        }
        @media (max-width:600px) {
            .peque{
font-size: 0.85rem!important;
            }
            }

            @media (min-width:681px)  {
            .papelsueldo{
                margin-top: 24px!important;
            }
        }

        @media (min-width:800px)  {
            .papelsueldo{
                margin-top: 16px!important;
            }
        }
        @media (max-width:710px)  {
        .elimind1{
            display:none!important
        }
    }

    .colore{
        background-color: transparent!important;
    }

    @media (max-width:710px)  {
    .margenIzq{
        margin-left: 0px!important;

    }
}

@media (max-width:710px)  {
    .margenDer{
        margin-right: 0px!important;
        
    }
}

@media (max-width:570px)   {
    .margenDer{
        width: 180px!important;
    }
}

.MuiStepLabel-iconContainer.Mui-disabled{
    background-color:transparent!important;
}

@media (max-width:500px) {
    .papelsueldo{
        width: 350px !important;
        max-width: 100%!important;
    }
}

.botonAgrego{
    color: #004e56!important;
    font-weight: 500!important;
}

.botonAgrego:hover{
    color: #004e56!important;
    color: #49a4ad!important;
    background-color: transparent!important;
    font-weight: 500!important;
}


@media (min-width: 600px) {
    .largo{
    width:50%!important
    }
    }